import * as React from "react"
import Seo from "../components/seo";
import Layout from "../components/layout";

const PressPage = () => (
    <Layout>
        <Seo title="Press"/>
        <div className="overflow-hidden lg:relative bg-white py-4 sm:py-8 lg:py-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-xl sm:px-6 lg:max-w-5xl lg:px-8">
                <h1>
                    <span className="block text-3xl font-extrabold text-gray-900 sm:text-4xl">Press</span>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                        <span className="text-green-500">Coming soon</span>...
                    </h1>
                    <span className="block text-lg text-gray-900"></span>
                </h1>
            </div>
        </div>
    </Layout>
)

export default PressPage
